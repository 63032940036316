
	import { getGoodsList, deleteGoodsInfo } from '@/api/goods'
	import { defineComponent, onMounted, reactive, ref, toRef, nextTick} from 'vue'
  import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
	import usePagination from '@/utils/composables/usePagination'
  import GoodsAdde from '@/views/goods/GoodsAdde.vue'
  
	export default defineComponent({
		components: {
			BasicTablePage,
      GoodsAdde
		},
		setup(){
      
      const isAjaxing = ref(false)
      
      const goodsAddeBox = ref()
      
			const tablePagination = usePagination()
			
      const searchData = reactive<AnyObject>({})
      
      const tableData = reactive<AnyArray>([])
			
      const searchInputs = reactive<Inputs>([
        {
          label: '货物名称',
          type: 'input',
          placeholder: '请输入货物名称',
          model: toRef(searchData, 'goodsName'),
          width: '200px',
          clearable: true
        }
      ])
      
      const searchButtons = reactive<Buttons>([
      	{
      	  label: '查询',
      	  icon: 'el-icon-search',
      	  onClick: () => {
            getTableData()
          }
      	},
        {
          label: '新增货物',
          icon: 'el-icon-plus',
          onClick: () => {
            goodsAddeBox.value.showAddeModal(0)
          }
        }
      ])
      
			const tableColumns = reactive<TableColumns>([
				{
					type: 'index',
					label: '序号',
					width: '80px',
					align: 'center'
				}, {
					prop: 'goodsName',
					label: '名称',
					align: 'center'
				}, {
					prop: 'goodsDesc',
					label: '描述',
					align: 'center'
				}, {
					prop: 'createdTime',
					label: '创建时间',
					align: 'center'
				}, {
					prop: 'modifiedTime',
					label: '修改时间',
          align: 'center'
				}, {
					type: 'render',
					label: '状态',
          align: 'center',
          render: (scope) => (scope.row.status==1 ? '在用' : '无效')
				}, {
					type: 'button',
					label: '操作',
          align: 'center',
					buttons: [{
						label: "修改",
						onClick: scope => {
              goodsAddeBox.value.showAddeModal(scope.row)
            }
					},{
						label: "删除",
						onClick: scope => {
              if(isAjaxing.value){
                return !ElMessage.info('正在删除...')
              }
              
              ElMessageBox.confirm('删除 “' + scope.row.goodsName + '” 货物，请确保该货物不再使用！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                isAjaxing.value = true
                deleteGoodsInfo(scope.row.goodsId).then(() => {
                  console.log('删除成功。。。')
                  ElMessage.success('已删除')
                  isAjaxing.value = false
                  setTimeout(() => getTableData(), 500)
                }).catch(() =>{
                  console.log('删除失败！！！')
                  isAjaxing.value = false
                })
              })//删除货物
            }
					}]
				}
			])
			
      const getTableData = async () => {
      	const respone = await getGoodsList(searchData)
        tableData.splice(0, tableData.length)
      	if(respone.data && respone.data.data){
          nextTick(() => {
            respone.data.data.forEach((item: AnyObject) => {
              tableData.push(item)
            })
          })
      	}
        return
      }
      
			onMounted(() => {
				getTableData()
			})
			
			return {
        getTableData,
				tableColumns,
				tableData,
        goodsAddeBox,
				tablePagination,
        searchInputs,
        searchButtons
			}
		}
	})
