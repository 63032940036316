
import { nextTick, onMounted, ref, toRefs, watch, computed, getCurrentInstance, reactive, toRef, PropType, defineComponent, onUpdated } from 'vue'
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import { Buttons, FormItems, RenderFunc, InputConfig, FormItem } from '@/types/BasicComponent'
import { enableDrag, authFilter, showableFilter, callIfFunction, renderButton } from './utils'
let counter = 0

export default defineComponent({
  components: {
    BasicInputGroup
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    formItems: {
      type: Array as PropType<FormItems>,
      default: []
    },
    formRules: {
      type: Object,
      default: {}
    },
    footerButtons: {
      type: Array as PropType<Buttons>,
      default: []
    },
    modelValue: {
      type: Boolean
    },
    appendToBody: {
      type: Boolean
    },
    dragable: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String]
    },
    labelWidth: {
      type: String,
      default: '165px'
    },
    top: {
      type: String,
      default: '15vh'
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'close', 'closed', 'open'],

  setup(props, {emit, slots}) {
    counter ++
    const { title, subTitle, formItems, footerButtons, modelValue } = toRefs(props)
    const visible = ref(modelValue.value)

    if (props.dragable) {
      onUpdated(() => {
        enableDrag(`.__basic-form-dialog-${counter}`, '.el-dialog__header')
      })
    }

    watch(modelValue, () => visible.value = modelValue.value, {flush: 'post'})
    watch(visible, () => emit('update:modelValue', visible.value))

    const validformItems = computed(() => authFilter(showableFilter(formItems.value)))

    const ruleForm = reactive({} as any)
    formItems.value.forEach(formItem => {
      ruleForm[formItem.prop] = toRef(formItem, 'model')
    })

    const renderContent = ref(false)
    const instance = getCurrentInstance() as any
    const formRef = ref()
    const onOpen = () => {
      // element plus bug, el-dialog 里的 date-picker 必须在 dialog 首次渲染后更新，否则不显示值
      for (let i in formItems.value) {
        const item = formItems.value[i]
        formItems.value[i].width = '100%'
        if (item.type === 'date') {
          const t = item.model
          item.model = (null as any)
          nextTick(() => item.model = t);
        }
      }
      renderContent.value = true
      nextTick(() => emit('open'))
    }

    watch(formRef, val => {
      if (val) {
        instance.ctx.resetFields = formRef.value.resetFields
        instance.ctx.validate = formRef.value.validate
      }
    })

    const onClosed = () => {
      emit('closed')
      renderContent.value = false
    }
    // @ts-ignore
    const inputGroupRender = (inputRender: JSX.Element, itemConfig: FormItem) => (
      <el-col span={itemConfig.fullRow? 24: 8}>
        <el-form-item
          prop={itemConfig.prop} 
          size="small"
          style={{ width: itemConfig.width }}
          v-slots={{
            default: () => <div style={{display: 'flex', justifyContent: 'center'}}>{inputRender}</div>,
            label: () => typeof itemConfig.label === 'function'? itemConfig.label() : itemConfig.label
          }}
        ></el-form-item>
      </el-col>
    )

    return () => (
      <el-dialog
        v-model={visible.value}
        onClose={() => emit('close')}
        onClosed={onClosed}
        onOpen={onOpen}
        custom-class={`dialog-container __basic-form-dialog __basic-form-dialog-${counter}`}
        width={props.width? props.width: '900px'}
        append-to-body={props.appendToBody}
        close-on-click-modal={props.closeOnClickModal}
        top={props.top}
        v-slots={{
          title: () => {
            return (
              <div>
                <span class="dialog-title">{title.value}</span>
                <span class="dialog-sub-title">{subTitle.value}</span>
              </div>
            )
          },
          default: () => {
            if (!renderContent.value) return <div></div>
            return (
              <el-form
                class="dialog-form" 
                style="padding: 0 50px 0 0; font-size: 15px; margin-top: 50px  display: flex; flex-wrap: wrap; justify-content: left" 
                model={ruleForm}
                rules={props.formRules}
                ref={formRef}
                label-width={props.labelWidth}
              >
                <el-row>
                  <basic-input-group
                    inputs={validformItems.value}
                    render={inputGroupRender}
                  ></basic-input-group>
                </el-row>

                {slots.body?.()}
              </el-form>
            )
          },
          footer: () => {
            if (slots.footer) return slots.footer()
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '50px' }}>
                {footerButtons.value.map(buttonConfig => renderButton(buttonConfig))}
              </div>
            )
          }
        }}
      ></el-dialog>
    )
  }
})
