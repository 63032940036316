import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodsAdde = _resolveComponent("GoodsAdde")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    pagination: _ctx.tablePagination,
    tableDefaultExpandAll: true,
    tableRowKey: "goodsId"
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_GoodsAdde, {
        ref: "goodsAddeBox",
        onReloadPageData: _ctx.getTableData
      }, null, 8, ["onReloadPageData"])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "pagination"]))
}