import { C6PageRequest, C6BaseResponse } from '@/types/API'
import { c6Request } from '@/utils/request'

/** 获取全部货物分类 */
export function getGoodsCategory(params: AnyObject) {
  return c6Request.get<C6BaseResponse>('/backstage/goods/getGoodsCategory', { params })
}

/** 新增或修改货物分类 */
export function addOrUpdateGoodsCategory(data: AnyObject) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/addOrUpdateGoodsCategory', data)
}

/** 删除货物分类 */
export function deleteGoodsCategory(categoryId: number) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/deleteGoodsCategory', categoryId, {
    headers: {"Content-Type": "application/json"}
  })
}

/** 获取全部货物信息 */
export function getGoodsList(params: AnyObject) {
  return c6Request.get<C6BaseResponse>('/backstage/goods/getGoodsList', { params })
}

/** 新增或修改货物信息 */
export function addOrUpdateGoodsInfo(data: AnyObject) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/addOrUpdateGoodsInfo', data)
}

/** 删除货物信息 */
export function deleteGoodsInfo(goodsId: number) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/deleteGoodsInfo', goodsId, {
    headers: {"Content-Type": "application/json"}
  })
}

/** 查询货物分组分页 */
export function getGoodsGroupPage(params: C6PageRequest<AnyObject>) {
  return c6Request.get<C6BaseResponse>('/backstage/goods/getGoodsGroupPage', { params })
}

/** 新增或修改货物分组信息 */
export function addOrUpdateGoodsGroup(data: AnyObject) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/addOrUpdateGoodsGroup', data)
}

/** 根据分组id获取货物分类与货物关系 */
export function getGoodsCategoryRelationByGroupId(groupId: number) {
  return c6Request.get<C6BaseResponse>('/backstage/goods/getGoodsCategoryRelationByGroupId', {params: { groupId }})
}

/** 更新货物分类分组关系 */
export function updateGoodsCategoryGroup(data: AnyObject) {
  return c6Request.post<C6BaseResponse>('/backstage/goods/updateGoodsCategoryGroup', data)
}