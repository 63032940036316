
  import { defineComponent, reactive, ref, onMounted } from '@vue/runtime-core'
  import { addOrUpdateGoodsInfo } from '@/api/goods'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Buttons } from '@/types/BasicComponent'
  import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'
  import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
  
  export default defineComponent({
  	components: {
  		BasicFormDialog
  	},
    emits: ['reloadPageData'],
  	setup(props, context){
      const isShowModal = ref(false)
      const isAjaxing = ref(false)
      const modalTitle = ref("")
      const formRules = reactive<FormRulesMap>({
      	/* goodsName: [
      		{ required: true, message: '请输入货物名称' },
      	],
      	goodsDesc: [
      		{ required: true, message: '请输入货物描述' },
      	] */
      })
      const footerButtons = reactive<Buttons>([
      	{
      		label: '提交',
      		type: 'primary',
      		onClick: () => {
            if(isAjaxing.value){
              return !ElMessage.info('正在提交...')
            }
            
            isAjaxing.value = true
            addOrUpdateGoodsInfo(formData).then(() => {
              console.log('成功。。。')
              context.emit('reloadPageData', 1)
              ElMessage.success('已提交')
              isAjaxing.value = false
              isShowModal.value = false
            }).catch(() =>{
              console.log('失败！！！')
              isAjaxing.value = false
            })
      		}
      	}, 
      	{
      		label: '取消',
      		type: 'default',
      		onClick: () => (isShowModal.value = false)
      	}
      ])
      const formData = reactive<AnyObject>({
      	goodsName: 	  "",
      	goodsDesc: 		"",
        createdTime:  "",
      	modifiedTime: "",
        status:       1
      })
      const showAddeModal = (infos: AnyObject) => {
        if(infos && infos.goodsId){
          formData.goodsId         = infos.goodsId
          formData.goodsName       = infos.goodsName
          formData.goodsDesc       = infos.goodsDesc
          formData.createdTime     = infos.createdTime
          formData.modifiedTime    = infos.modifiedTime
          formData.status          = infos.status
          modalTitle.value         = "修改货物"
        }else{
          formData.goodsId         = ""
          formData.goodsName       = ""
          formData.goodsDesc       = ""
          formData.createdTime     = ""
          formData.modifiedTime    = ""
          formData.status          = 1
          modalTitle.value         = "添加货物"
        }
        
        isShowModal.value = true
        isAjaxing.value = false
      }
      
      return {
        isShowModal,
        modalTitle,
        footerButtons,
        formRules,
        formData,
        showAddeModal
      }
    }
  })
